/* You can add global styles to this file, and also import other style files */
@import url('../../../shared/src/assets/fonts/fonts.css');
@import '../../../shared/src/assets/css/style.scss';

*,
body {
  font-family: 'Outfit-Regular';
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.list-style-none {
  list-style: none;
}
a {
  text-decoration: none !important;
}
a:hover,
a:focus,
a:active {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
:focus-visible {
  outline: none;
}
$pdl-radius: 8px;

// Custom Scroll
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #d9d9d9;
  border-radius: $pdl-radius;
  width: 7px;
  border: 1px solid #ffffff;
}
::-webkit-scrollbar {
  width: 7px;
  background-color: #d9d9d9;
  border-radius: $pdl-radius;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: $pdl-radius;
  width: 7px;
}

// Custom Search Focus
::-webkit-search-cancel-button {
  position: relative;
  right: -5px;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: transparent;
  background-image: url('/assets/images/icons/search-close-ico.svg');
}

// Global Classes
.heading-dark {
  color: #272936;
}
.pdl-grey {
  color: #5d6b82;
}
.bg-pdl-color {
  background-color: #f1eee3;
}
.pdl-bg-green {
  background-color: var(--pdl-green);
}
.pdl-title-color {
  color: #091e42;
}
.pdl-light-grey {
  color: #98a1b0;
}
.semibold {
  font-family: 'Outfit-SemiBold';
}
.light-font {
  font-family: 'Outfit-Light';
}
.medium-font {
  font-family: 'Outfit-Medium';
  font-optical-sizing: auto;
  font-weight: 500;
}
.link-green {
  color: #477958;
  text-decoration: underline !important;
  // margin-bottom: 0.5rem;
  display: flex;
}
.single-line {
  line-height: 1;
}
.font-10 {
  font-size: 0.625rem;
}
.font-12 {
  font-size: 0.75rem;
}
.font-14 {
  font-size: 0.875rem;
}
.font-31 {
  font-size: 31px !important;
}
.section-padding {
  padding: 4rem 0;
}
.mb-lg-40 {
  margin-bottom: 2.5rem;
}
.font-regular {
  font-family: 'Outfit-Regular';
  font-weight: 400;
}
.visible-below-767 {
  display: none;
}
.visible-below-991 {
  display: none;
}
.centered-btn {
  .pdl-btn,
  .pdl-btn:hover,
  .pdl-btn:focus {
    background-color: var(--pdl-green);
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    border-radius: var(--pdl-radius);
    padding: 11px 20px;
    line-height: normal;
    min-height: 44px;
  }
}
.pdl-bg {
  background-color: #f0ede4;
}
.pdl-bg-white {
  background-color: #f9f6ee;
}
.section-lg-mt-4 {
  margin-top: 4rem;
}
.section-lg-mb-4 {
  margin-bottom: 4rem;
}
.form-check-input:checked {
  background-color: #477958 !important;
  border-color: #477958;
  box-shadow: none !important;
  color: #272936;
}
.form-check {
  margin-bottom: 0.813rem !important;
  input + label {
    color: #7a8699 !important;
    font-size: 16px;
  }
  input:checked + label {
    color: #272936 !important;
  }
}

// Advanced Search page

.filter-expanding {
  .accordian {
    .accordian-item {
      border: 0;
      .accordion-header {
        .accordion-button {
          padding: 0;
          border: 0;
          background: none;
          background-color: #ffffff;
        }
        .accordion-button:not(.collapsed) {
          padding: 0;
          border: 0;
          background: none;
          background-color: #ffffff;
        }
      }
    }
  }
  .accordion-item {
    border: 0;
    padding: 0;
    box-shadow: none;
    .accordion-button {
      padding: 0;
      border: 0;
      background: none;
      background-color: #ffffff;
      box-shadow: none;
    }
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.accordion-button::after {
  background-image: url('assets/images/icons/chevron-down.svg') !important;
  width: 12px;
  height: 12px;
  background-size: 12px !important;
  background-position: right center !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url('assets/images/icons/chevron-down.svg') !important;
  width: 12px;
  height: 12px;
  background-size: 12px !important;
  background-position: right center !important;
}

// Global Responsive Classes
@media screen and (min-width: 992px) {
  .col-pdl-lg-20 {
    width: 20% !important;
  }
}
@media screen and (max-width: 991.5px) {
  .col-pdl-md-33 {
    width: 33.33% !important;
  }
  .section-padding {
    padding: 2.5rem 0 !important;
  }
  .section-sm-mt-25 {
    margin-top: 2.5rem;
  }
  .section-sm-mb-25 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-40 {
    margin-bottom: 1.5rem !important;
  }
  .padding-top-md-0 {
    padding-top: 0 !important;
  }

  .hidden-after-991 {
    display: none !important;
  }
  .visible-below-991 {
    display: block !important;
  }

  // Home Page Video Gallery
  .video-gallery {
    .video-block {
      iframe {
        max-height: 350px !important;
        height: 350px !important;
      }
    }
  }
}

@media screen and (max-width: 767.5px) {
  .hidden-after-767 {
    display: none !important;
  }
  .visible-below-767 {
    display: block !important;
  }

  // home Page Video Gallery
  .video-gallery {
    .video-block {
      iframe {
        max-height: 280px !important;
        height: 280px !important;
      }
    }
  }
}

@media screen and (max-width: 575.5px) {
  .sm-font-0 {
    font-size: 0px !important;
  }
  .col-pdl-sm-6 {
    width: 50% !important;
  }

  // home Page Video Gallery
  .video-gallery {
    .video-block {
      iframe {
        max-height: 250px !important;
        height: 250px !important;
      }
    }
  }
}

@media screen and (max-width: 375.5px) {
  .sm-font-0 {
    font-size: 0px !important;
  }
  .col-pdl-sm-6 {
    width: 50% !important;
  }

  // home Page Video Gallery
  .video-gallery {
    .video-block {
      iframe {
        max-height: 200px !important;
        height: 200px !important;
      }
    }
  }
}

// Home Page Slider

.home-slider {
  button.slick-prev.slick-arrow,
  button.slick-next.slick-arrow {
    position: absolute !important;
    z-index: 999 !important;
    background-color: #ffffff !important;
    opacity: 1 !important;
    top: 50% !important;
    transform: translate(0, -50%) !important;
    width: 36px !important;
    height: 38px !important;
    border-radius: 4px !important;
    &::before {
      display: none;
    }
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 575px) {
      display: none !important;
    }
  }
  button.slick-prev.slick-arrow {
    left: 44px !important;
    background-image: url('/assets/images/icons/previous-ico.svg') !important;
  }
  button.slick-next.slick-arrow {
    left: auto;
    right: 44px !important;
    background-image: url('/assets/images/icons/next-ico.svg') !important;
  }
}

// Most Popular Reads Slider
.most-popular-slider {
  .slick-slider {
    .slick-list {
      padding-left: 0 !important;
      // .slick-track {
      //     transform: none !important;
      // }
    }
  }
}

// Home Page Categories Slider

.categories-slider {
  .slick-list {
    padding-left: 0 !important;
  }
}

// Home Page Curated Collections Slider

.collections-in-slider {
  .slick-list {
    padding-left: 0 !important;
  }
}

// Home Page Downloads Slider

.downloads-in-slider {
  .slick-list {
    padding-left: 0 !important;
  }
}
// video::-webkit-media-controls {
//     opacity: 0 !important;
// }

//   video::-webkit-media-controls-play-button {display: none;}

//   video::-webkit-media-controls-volume-slider {display: none;}

//   video::-webkit-media-controls-mute-button {display: none;}

//   video::-webkit-media-controls-timeline {display: none;}

//   video::-webkit-media-controls-current-time-display {display: none;}

// Home Page Video Gallery Slider\

.videos-in-slider {
  .slick-list {
    padding-left: 0 !important;
  }
}

// Inner Page Header Banner

.innerpage-banner {
  background-image: url('/assets/images/inner-banner-bg.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .breadcrumb {
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    a {
      color: #7a8699;
      font-size: 0.625rem;
      img {
        margin-top: -3px;
      }
    }
    .active {
      color: var(--green-secondary);
    }
    span {
      width: 1.25rem;
      margin: 0 0.5rem;
      text-align: center;
      img {
        margin-top: -3px;
      }
    }
  }
  .page-title {
    margin-bottom: 1.938rem;
    text-align: center;
    h1 {
      font-size: 1.938rem;
    }
  }
}

// Downloads Slider

.downloads-slider {
  .slick-slider {
    @media screen and (max-width: 767.5px) {
      margin-bottom: 0;
    }
    .slick-list {
      .slick-track {
        .downloads-slide {
          padding-right: 40px !important;
          padding-left: 40px;
          .downloads-slide-card {
            .block-img {
              text-align: center;
              display: flex;
              justify-content: center;
              padding-top: 2.5rem;
              margin-bottom: 1.813rem;
              img {
                max-width: 100%;
                max-height: 460px;
              }
            }
            .block-text-bar {
              h6 {
                font-size: 1.938rem;
                color: #ffffff;
                text-align: center;
                @media screen and (max-width: 767.5px) {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .slick-dots {
      display: none;
    }
    .slick-prev {
      left: 0;
      width: 38px;
      height: 38px;
      background-color: #ffffff;
      border-radius: var(--pdl-radius);
      background-image: url('assets/images/icons/arrow-left-ico.svg');
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 999;
      &::before {
        display: none;
      }
    }
    .slick-next {
      right: 0;
      width: 38px;
      height: 38px;
      background-color: #ffffff;
      border-radius: var(--pdl-radius);
      background-image: url('assets/images/icons/arrow-right-ico.svg');
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 999;
      &::before {
        display: none;
      }
    }
  }
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    display: none;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    background: transparent;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    display: none;
    background: transparent;
  }
}

.webPagination__wrapper {
  padding: 20px 15px;
  background: var(--white-color);
  margin-top: 5px;
  
  .pagination {
    margin-bottom: 0;
  }
  
  .page-item {
    margin: 0 2px;
    border-radius: 6px;
    width: 36px;
    height: 38px;

    // First Page Button
    &:first-child .page-link {
      background: transparent url('/assets/images/icons/custom-first.svg')
        no-repeat center center;
      background-size: 18px;
      height: 100%;
      padding: 0.75rem 0.375rem;
    }

    // Last Page Button
    &:last-child .page-link {
      background: transparent url('/assets/images/icons/custom-last.svg')
        no-repeat center center;
      background-size: 18px;
      height: 100%;
      padding: 0.75rem 0.375rem;
    }

    // Previous Button
    &:nth-child(2) .page-link {
      background: transparent url('/assets/images/icons/custom-prev.svg')
        no-repeat center center;
      background-size: 18px;
      height: 100%;
      padding: 0.75rem 0.375rem;
    }

    // Next Button
    &:nth-last-child(2) .page-link {
      background: transparent url('/assets/images/icons/custom-next.svg')
        no-repeat center center;
      background-size: 18px;
      height: 100%;
      padding: 0.75rem 0.375rem;
    }

    &:first-child .page-link span,
    &:last-child .page-link span,
    &:nth-child(2) .page-link span,
    &:nth-last-child(2) .page-link span {
      display: none;
    }

    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #dad4be;
      border-radius: 6px;
      background-color: #fbfbf9;
      font-size: 16px;
      color: #7a8699;
      transition: background-color 0.2s ease, color 0.2s ease;
      text-decoration: none;

      &:hover {
        background-color: #f0ede4;
        color: #243757;
      }
    }

    &.active .page-link {
      background-color: #4b6147;
      border-color: #4b6147;
      color: #ffffff;
    }

    &.disabled .page-link {
      border-color: #ddd;
      color: #ccc;
      background-color: #f9f9f9;
      pointer-events: none;
      cursor: default;
    }
  }
}

.static-section {
  .container-768 {
    max-width: 768px;
  }

  .container-1000 {
    max-width: 1000px;
    margin: 0 auto;
  }

  .container-1400 {
    max-width: 1400px;
    margin: 0 auto;
  }

  .teamsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 599px) {
    .teamsWrapper {
      grid-template-columns: 1fr;
    }
  }

  .static_table {
    background-color: #edf2ee;
    text-transform: uppercase;
    font-family: 'Outfit-Medium';
    color: var(--bs-gray-700);
    font-size: 14px;
    vertical-align: middle;
    border-radius: 8px;
    thead,
    tbody {
      border-radius: 8px;
      tr {
        th {
          background-color: #edf2ee !important;
        }
        th,
        td {
          line-height: 12px;
          color: #42526d;
          font-size: 10px !important;
          &:nth-of-type(1),
          &:nth-of-type(2) {
            width: 400px !important;
          }
          &:nth-of-type(3),
          &:nth-of-type(1) {
            text-align: left !important;
            padding-left: 20px;
          }
          .p-6-12 {
            * {
              padding: 6px 20px !important;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .email {
    font-weight: 700 !important;
    text-decoration: underline !important;
  }
  .text {
    line-height: 23.4px;
    color: #42526d;
    font-size: 16px !important;
  }
  .heading {
    line-height: 25px;
    color: #091e42;
    font-size: 24px;
    font-weight: 500;
  }
  .subheading {
    font-weight: 500;
    font-size: 16px;
    line-height: 23.4px;
  }
  .cg-5 {
    column-gap: 5px;
  }
  .lh-20 {
    line-height: 20px;
    font-weight: 400 !important;
    font-size: 16px;
    span {
      font-weight: 900;
      font-size: 14px;
    }
  }
  .inline-text-div {
    height: 28px;
    column-gap: 5px;
    h1 {
      margin-bottom: 0;
    }
    h1,
    span {
      line-height: 28px;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .hyphen {
    list-style-type: none;
    padding-left: 16px;
  }
  .hyphen > li:before {
    content: '-';
    text-indent: -15px;
  }
  @media screen and (max-width: 991px) {
    .workingGroup {
      flex-direction: column;
      overflow: hidden;
      .img {
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        img {
          width: 90vw;
          margin-bottom: 20px;
          height: 300px;
          object-fit: cover;
        }
      }
    }
  }
  .workingGroup {
    column-gap: 60px;

    @media screen and (min-width: 992px) {
      .img {
        // width: 45vw;
        max-width: 500px;
        img {
          object-fit: cover;
          max-width: 500px;
          min-height: 100%;
        }
      }
    }
  }
}
.description-text {
  display: block;
  * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}
//curated collections
.threeImgCollection {
  display: flex;
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  height: 180px;
  width: 100%;
  img {
    height: 150px;
    width: 100px;
    object-fit: cover;
    align-items: flex-end;
    border-radius: 8px;
    &:nth-of-type(2) {
      width: 222px;
      height: 222px;
      position: absolute;
      left: calc(50% - 111px);
      overflow: hidden;
      margin-top: -30px;
      @media screen and (max-width: 767px) {
        width: min(50%, 222px);
        width: 111px;
        left: calc(50% - 55px);
      }
      @media screen and (max-width: 575px) {
        width: 222px;
        left: calc(50% - 111px);
      }
      @media screen and (max-width: 380px) {
        width: 111px;
        left: calc(50% - 55px);
      }
    }
    &:nth-of-type(1),
    &:nth-of-type(3) {
      flex-grow: 1;
    }
  }
}
.twoImgCollection {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 180px;
  padding-top: 30px;
  img {
    height: 150px;
    width: 100px;
    object-fit: cover;
    align-items: flex-end;
    border-radius: 8px;
    overflow: hidden;
    &:nth-of-type(1) {
      width: 222px;
      height: 222px;
      left: calc(50% - 111px);
      overflow: hidden;
      margin-top: -30px;
    }
    &:nth-of-type(2) {
      position: absolute;
      left: calc(50% - 111px);
      width: 222px;
      height: 222px;
    }
  }
}
.exhibition_description_ellipses {
  p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.clamp-9 {
  p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.clamp-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pdl-btns-row-fixed {
  position: absolute;
  bottom: 20px;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    .btn {
      margin: 0 !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    .btn {
      margin: 0 !important;
    }
  }
}
.clamp-9.description-text {
  p {
    margin-bottom: 21px;
  }
}

.description-text.exhibition_description_ellipses {
  max-height: 48px;
  overflow: hidden;
  margin-top: 12px;
}
