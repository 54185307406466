:root {
    --block-bg : #F0EDE4;
    --link-active: #091E42;
    --pdl-radius : 8px;
    --pdl-green : #477958;
    --green-primary: #32553E;
    --green-secondary: #2B4A36;
    --err-color: #dc3545;
    --text-primary-color: #243757;
    --text-secondary-color: #7A8699;
    --border-color: #EBEDF0;
    --light-green: #94B19E;
    --white-color: #ffffff;
    --gray-text: #A6AEBB;
    --text-tertiary-color: #42526D;
    --green-status: #16A34A;
    --green-status-bg: #E4FFE9;
    --dashboard-grey-bg: #F8F8F8;
    --table-th-color: #EDF2EE;
    --dark-gray: #5D6B82;
    --orange-text: #FF9F43;
    --orange-text-bg: #FFF3E8;
    --tertiary-color: #669074;
    --light-green-bg: #B4C8BB;
    --green-lighter:#D4DED8;
    --gray-dark: #6B788E;
    --alert-color-bg: #FCEFF0;
    --alert-color: #EEA3A6;
    --link-blue: #0065FF;
    --light-blue: #E6F0FF;
    --light-pink: #F9F4F4;
    --yellowish-gray: #F9F6EE;
    --pdl-blue: #3B5998;
    --pdl-red: #DB4437;
    --form-input-color: #C2C7D0;
    --gray-bg : #DFE2E6;
    --success-alert: #28C76F;
    --error-alert: #EA5455;
    --warning-alert: #FF9F43;
    --success-alert-bg: #B9E8CE;
    --error-alert-bg: #F2BFBF;
    --warning-alert-bg: #F8D8B9;
    --alice-blue: #F5F6F7;
    --gulf-blue: #354764;
    --web-ready: #EB7535;
    --doc-status-offline-color: #98A1B0;
    --popover-text: #858174;
    --pipe-color : #d9d9d9;
    --secondary-s300: #96AE9F
}
