@use '../mixins/mixins.scss' as mixin;
@import '../mixins/variable.scss';

html {
  scrollbar-width: none;     
  -ms-overflow-style: none;  
  overflow: auto;         
}

html::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.tooltip-custom-class .popover-body {
  max-height: 300px;
  overflow-y: auto;
}

// App Spinner
.app-spinner {
  .modal-content {
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    text-align: center;
  }
}

*,
body {
  font-family: 'Outfit-Light', sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-primary-color);
}
.list-style-none {
  list-style: none;
}
a:hover,
a:focus,
a:active {
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}
textarea {
  resize: none;
}
// Custom Scroll
.dashboard ::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: var(--green-primary);
  border-radius: var(--pdl-radius);
  width: 4px;
  height: 4px;
  // border: 1px solid var(--white-color);
}
.dashboard ::-webkit-scrollbar {
  width: 4px;
  background-color: #6e7e74;
  border-radius: var(--pdl-radius);
  height: 4px;
}
.dashboard ::-webkit-scrollbar-thumb {
  background-color: #6e7e74;
  border-radius: var(--pdl-radius);
  width: 4px;
}
.merging__data__item__container::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #d9d9d9;
  border-radius: var(--pdl-radius);
  width: 4px;
}
.merging__data__item__container::-webkit-scrollbar {
  width: 4px;
  background-color: #d9d9d9;
  border-radius: var(--pdl-radius);
}
.merging__data__item__container::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: var(--pdl-radius);
  width: 4px;
}
/* dashboard */
.dashboard {
  display: flex;
  &__main {
    width: 100%;
    height: 100vh;
    background: var(--dashboard-grey-bg);
    overflow-x: auto;
  }
  &__content {
    flex: 1 0 auto;
    padding: 10px;
    height: calc(100vh - 20px);
  }
}
// common style for Admin and Staff Dashboard starts

.tabs__section {
  .nav-tabs {
    border-color: #dfe2e6;
    .nav-link {
      border: 0;
      padding: 8px 0 8px;
      margin-right: 20px;
      color: var(--text-primary-color);
      font-family: 'Outfit-Regular';
      font-size: 16px;
      border-bottom: 2px solid transparent;
      &:hover,
      &:focus {
        border-color: var(--pdl-green);
      }
      &.has__children {
        margin-right: 50px;
      }
    }
    .nav-item.show .nav-link,
    .nav-link.active {
      border-bottom: 2px solid var(--pdl-green);
      background-color: transparent;
      color: var(--pdl-green);
      font-family: 'Outfit-Medium';
    }
  }
  .tab-content {
    margin-top: 12px;
  }
}

// Table Structure
.table__dashboard {
  table {
    background-color: transparent;
    margin-bottom: 0;
    thead {
      tr {
        th {
          color: var(--green-secondary);
          font-size: 10px;
          text-transform: uppercase;
          font-family: 'Outfit-Light';
          padding: 20px 10px;
          border: 0;
          background-color: var(--table-th-color);
          position: sticky;
          top: 0;
          vertical-align: middle;
          .check__box {
            cursor: pointer;
            border-color: var(--green-secondary);
          }
        }
        th:first-child {
          border-top-left-radius: 8px;
        }
        th:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: var(--text-secondary-color);
          font-size: 14px;
          padding: 20px 10px;
          border-color: var(--border-color);
          background-color: var(--white-color);
          a {
            color: var(--text-primary-color);
            line-height: 25.2px;
            display: inline-block;
          }
          &:last-child {
            a {
              margin: 5px 0;
            }
          }
          .form-switch {
            display: inline-block;
            vertical-align: middle;
            .form-check-label {
              color: var(--text-primary-color);
              margin-left: 10px;
              vertical-align: sub;
            }
            .form-check-input {
              width: 42px;
              height: 24px;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
              background-color: var(--table-th-color);
              border-color: var(--border-color);
              cursor: pointer;
              &:checked,
              &.statusChecked {
                background-color: var(--green-status);
                border-color: var(--green-status);
                background-position: right center;
              }
              &.web__ready {
                &:checked,
                &.statusChecked {
                  background-color: #eea3a6;
                  border-color: #eea3a6;
                }
                + label {
                  color: #eea3a6;
                }
              }
              &.statusUnchecked {
                background-color: var(--table-th-color);
                border-color: var(--border-color);
                background-position: left center;
              }
              &:focus {
                border-color: #16a34a24;
                box-shadow: 0 0 0 0.25rem #16a34a24;
                // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
              }
            }
          }
        }
      }
    }
  }
  .table__striped {
    tbody {
      tr:nth-child(even) {
        background-color: var(--border-color);
      }
    }
  }
}
// Form Style
.form__container {
  border: 15px solid #eae6d9;
  justify-content: center;
  align-items: center;
  background: #f0ede4 url('/assets/images/icons/pattern.svg') repeat center
    center;
  height: 100vh;
  overflow-y: auto;
  padding: 0;
  .scrollable__area {
    height: calc(100vh - 130px);
    overflow-y: auto;
    margin: 20px 0;
  }
}
.form__wrapper {
  border: 1px solid #eae6d9;
  width: 800px;
  background-color: #fbfbf9;
  border-radius: var(--pdl-radius);
  padding: 45px 20px;
  max-width: 90%;
  margin: auto;
}
.form__style {
  form {
    width: 440px;
    margin: 0 auto;
    max-width: 100%;
    label {
      font-family: 'Outfit-Light';
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0em;
      margin-bottom: 5px;
    }
    .form__field {
      @include mixin.form__field;
    }

    .form-text {
      color: var(--gray-text);
      font-size: 10px;
      text-align: right;
    }
    .info__msg {
      color: var(--gray-text);
      position: absolute;
      bottom: -18px;
      font-size: 10px;
      right: 0;
    }
    .field__section {
      position: relative;
      .err__msg {
        @include mixin.err__msg;
      }
    }
    .btn__secondary {
      @include mixin.btn__secondary;
      padding: 7px 10px;
    }
    .btn__primary {
      @include mixin.btn__primary;
      padding: 11px 10px;
      width: 240px;
    }
  }
  .btn__secondary {
    @include mixin.btn__secondary;
    padding: 7px 10px;
  }
  .form__title,
  .web__name,
  .more__details {
    text-align: center;
  }
  .form__title {
    font-family: 'Outfit-Light';
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: var(--pdl-green);
    text-transform: uppercase;
  }
  .web__name {
    font-family: Unna-Regular;
    font-size: 39px;
    line-height: 45px;
    letter-spacing: 0em;
    color: #091e42;
    margin: 20px 0 25px;
  }
  .more__details {
    font-family: 'Outfit-Regular';
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    color: #091e42;
  }
  .other__details {
    a {
      font-family: 'Outfit-Light';
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0em;
      color: #0065ff;
      text-decoration: underline;
    }
  }
}
.copyright-section {
  text-align: center;
  padding-bottom: 30px;
  p {
    color: #999485;
    font-family: 'Outfit-Light';
    margin: 0;
  }
}
.table__top__section {
  .sort__filter {
    width: auto;
    font-size: 16px;
    border: none;
    background: transparent url(/assets/images/icons/drop-down-green.svg)
      no-repeat center right 10px;
    color: var(--pdl-green);
    padding: 8px 35px 10px 20px;
    height: 44px;
  }
  .search-autocomplete {
    .cross-icon {
      position: absolute;
      right: 10px;
      top: 13px;
      cursor: pointer;
    }
    .auto__suggested {
      position: absolute;
      top: 43px;
      z-index: 1;
      right: 0;
      left: 0;
    }
  }
}
// buttons
.table__top__section {
  p {
    font-size: 16px;
    color: var(--text-secondary-color);
    padding-bottom: 25px;
    font-family: 'Outfit-Light';
  }
}
// Upload banner section

.main__wrapper {
  background-color: var(--white-color);
  padding: 35px 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--pdl-radius);
  margin-bottom: 22px;
}
// .browse__image {
//     position: relative;
//     img {
//         width: 100%;

//     }
//     .overlay__button {
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         label {
//             display: inline-block;
//             background-color: var(--white-color);
//             color: var(--text-primary-color);
//             padding: 10px 20px;
//             border-radius: 0.3rem;
//             cursor: pointer;
//             font-size: 16px;
//         }
//     }

// }
.inner__title {
  font-size: 20px;
  color: var(--text-primary-color);
  font-family: 'Outfit-Medium';
  margin-bottom: 0;
  margin-right: 68px;
}
.link__btn,
.btn.btn__close {
  border: 0;
}
.btn__close {
  &:hover {
    opacity: 0.9;
  }
}
.btn.link__btn {
  padding: 0;
  border: 0;
  color: var(--text-primary-color);
  &:hover,
  &:focus,
  &:active:focus {
    border: 0;
  }
  img {
    margin-top: -1px;
  }
}
.btn:active {
  background-color: inherit;
  border: inherit;
}
.btn.btn__primary,
.btn.btn__secondary,
.btn.btn__tertiary {
  font-size: 16px;
  font-family: 'Outfit-Regular';
  border-radius: var(--pdl-radius);
  &:hover {
    opacity: 0.8;
    transition: ease 1s;
  }
}
.btn.btn__primary,
.btn.btn__primary:disabled {
  background-color: var(--pdl-green);
  color: var(--white-color);
  max-width: 100%;
  border: 1px solid var(--pdl-green);
  &:focus,
  &:hover,
  &:active:focus {
    background-color: var(--pdl-green);
    color: var(--white-color);
    border: 1px solid var(--pdl-green);
  }
  // &:hover {
  //     opacity: 0.8;
  //     transition: ease 1s;
  // }
}

.btn.btn__secondary,
.btn.btn__secondary:disabled {
  color: var(--pdl-green);

  border: 1px solid var(--pdl-green);
  background-color: var(--white-color);
  &:focus,
  &:hover,
  &:active,
  &:active:focus {
    color: var(--pdl-green);
    border: 1px solid var(--pdl-green);
  }
}
.btn.btn__primary:disabled,
.btn.btn__secondary:disabled,
.btn.btn__tertiary:disabled {
  opacity: 0.4;
}
.btn.btn__tertiary {
  background-color: var(--table-th-color);
  color: var(--tertiary-color);
  border: 1px solid var(--table-th-color);
  &:focus,
  &:hover,
  &:active,
  &:active:focus {
    color: var(--tertiary-color);
    border: 1px solid var(--table-th-color);
    background-color: var(--table-th-color);
  }
}

.table__top__section {
  .inner__search {
    position: relative;
    .search-label {
      position: absolute;
      top: 8px;
      left: 26px;
    }
  }
}
.table__top__section,
.reader__search {
  .search__input {
    color: var(--text-secondary-color);
    font-size: 14px;
    border-color: var(--border-color);
    border-radius: var(--pdl-radius);
    padding: 9px 30px 9px 55px;
    min-width: 320px;
    // background: var(--white-color) url('/assets/images/icons/search-grey.svg') no-repeat 25px center ;
    height: 44px;
  }
  .btn__secondary,
  .btn__primary {
    min-width: 130px;
    padding: 5px 20px;
    height: 44px;
  }
}

.action__form {
  width: 395px;
  max-width: 90%;
  .btn__secondary,
  .btn__primary {
    width: 100%;
    padding: 14px 12px;
  }
  .form__field {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--white-color);
    font-family: 'Outfit-Light';
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0em;
    padding: 8px 15px;
    color: var(--text-secondary-color);
    &:focus {
      color: var(--text-secondary-color);
    }
    &:disabled {
      background-color: var(--dashboard-grey-bg);
    }
  }
  .form-label {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .field__section {
    position: relative;
  }
  .info__msg {
    color: var(--gray-text);
    position: absolute;
    bottom: -18px;
    font-size: 10px;
    right: 0;
  }
  .err__msg {
    color: var(--err-color);
    position: absolute;
    bottom: -18px;
    font-size: 10px;
    left: 0;
  }
}
.tooltip__link {
  color: var(--pdl-green);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.buttons__bottom {
  .btn {
    min-width: 197px;
    padding: 13px 12px;
  }
}
.form-check-input.check__box {
  font-size: 16px;
  border-color: var(--text-secondary-color);
  border-width: 2px;
  margin-top: 0;
  &:checked {
    background-color: #669074;
    border-color: #669074;
  }
}
.form-check-input.check__box__light {
  font-size: 16px;
  background-color: var(--table-th-color);
  border-color: var(--table-th-color);
  border-width: 2px;
  margin-top: 0;
  &:checked {
    border-color: var(--light-green-bg);
    background-color: var(--light-green-bg);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23477958' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    background-size: 14px;
  }
}
// pagination

.pagination__wrapper {
  padding: 20px 15px;
  background: var(--white-color);
  margin-top: 5px;
  p {
    color: #c2c7d0;
    font-size: 13px;
  }
  .pagination,
  p {
    margin-bottom: 0;
  }
  &:not(.page-item):first-child {
    background-color: var(--table-th-color);
  }
  .page-item {
    background-color: var(--table-th-color);
    &:first-child .page-link {
      background: transparent url('/assets/images/icons/arrow-prev.svg')
        no-repeat center center;

      background-size: 18px;
    }
    &:last-child .page-link {
      background: transparent url('/assets/images/icons/arrow-next.svg')
        no-repeat center center;

      background-size: 18px;
    }
    &:first-child .page-link span,
    &:last-child .page-link span {
      display: none;
    }
    &:first-child .page-link,
    &:last-child .page-link {
      padding: 4px 0;
    }
    &:first-child {
      margin-right: 10px;
      border-radius: 50%;
    }
    &:last-child {
      margin-left: 10px;
      border-radius: 50%;
    }
    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:nth-last-child(2) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .page-link {
      border: none;
      border: 0;
      color: var(--green-secondary);
      font-size: 13px;
      background-color: transparent;
      height: 30px;
      width: 30px;
      text-align: center;
      padding: 5px 0;
      font-family: 'Outfit-Regular';
    }
    &.disabled {
      background-color: var(--border-color);
    }
  }

  .page-item {
    &.active {
      .page-link {
        background-color: var(--green-primary);
        color: var(--white-color);
        border-radius: 50%;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(22, 163, 74, 0.1411764706);
        }
      }
    }
  }
}
.has__children {
  position: relative;
  &::after {
    // background-color: url(/assets/images/icons/chevron-down-icon.svg);
    content: '';
    width: 20px;
    height: 20px;
    border: 0;
    background-image: url(/assets/images/icons/chevron-down-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: -34px;
    top: 0.7rem;
    transform: none;
  }
}
.sort__by {
  position: relative;
  p {
    margin-bottom: 0;
    padding-bottom: 0;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-56%);
    .ascending__order {
      svg {
        margin-top: -2px;
      }
    }
    .active {
      svg {
        path {
          opacity: 0.4;
        }
      }
    }
  }
  select {
    padding-left: 32px;
  }
}
.table__top__section .form-switch,
.form-switch.form__switch {
  display: inline-block;
  vertical-align: middle;
  .form-check-label {
    color: var(--text-primary-color);
    margin-left: 10px;
    vertical-align: sub;
  }
  .form-check-input {
    width: 42px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
    background-color: var(--table-th-color);
    border-color: var(--border-color);
    &:checked {
      background-color: var(--green-status);
      border-color: var(--green-status);
    }
    &:focus {
      border-color: #16a34a24;
      box-shadow: 0 0 0 0.25rem #16a34a24;
    }
  }
}
.action__form {
  .form-control:-ms-input-placeholder {
    color: var(--gray-text); /* Internet Explorer 10-11 */
  }
  .form-control::-ms-input-placeholder {
    color: var(--gray-text); /* Microsoft Edge */
  }
  .form-control::placeholder {
    color: var(--gray-text); /* Firefox, Chrome, Opera */
  }
  .sort__filter.select__grey {
    color: var(--text-secondary-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237A8699' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  }
}
// table header sort
.table__dashboard {
  th[sortable] {
    cursor: pointer;
  }
  th[sortable] > .sort-icon,
  th[sortable] > div .sort-icon {
    content: '';
    display: inline-flex;
    background-size: 12px;
    width: 12px;
    height: 12px;
    margin-left: 4px;
    vertical-align: text-top;
  }
  th[sortable]:not(.asc):not(.desc) > .sort-icon,
  th[sortable]:not(.asc):not(.desc) > div > .sort-icon {
    background: url(../images/icons/table-sort-icon.svg) center no-repeat;
  }
  th[sortable].asc > .sort-icon,
  th[sortable].desc > .sort-icon,
  th[sortable].asc > div > .sort-icon,
  th[sortable].desc > div > .sort-icon {
    width: 12px;
  }
  th[sortable].asc > .sort-icon,
  th[sortable].asc > div > .sort-icon {
    background: url(../images/icons/sort-asc.svg) center no-repeat;
  }
  th[sortable].desc > .sort-icon,
  th[sortable].desc > div > .sort-icon {
    background: url(../images/icons/sort-desc.svg) center no-repeat;
  }
  th[sortable].desc:before,
  th[sortable].asc:before {
    content: none;
  }
}
.auto__suggested {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--white-color);
  font-family: 'Outfit-Light';
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0em;
  padding: 8px 15px;
  color: var(--text-secondary-color);
  max-height: 400px;
  overflow-y: auto;
  p {
    margin: 5px 0;
    cursor: pointer;
    &:hover {
      font-family: 'Outfit-Regular';
    }
  }
}
.modal__style {
  box-shadow: 0px 4px 15px 0px #2c3f5859;
  border-radius: var(--pdl-radius);
  padding: 30px;
  text-align: center;
  .modal-title {
    font-size: 20px;
    color: var(--text-primary-color);
    font-family: 'Outfit-Medium';
  }
  .modal-body {
    color: #6e6b7b;
    font-size: 13px;
  }
  .btn__close,
  .btn__close:hover,
  .btn__close:focus,
  .btn__close:active {
    background-color: var(--white-color);
    position: absolute;
    right: -6px;
    top: -6px;
    box-shadow: 0px 2px 2px 0px rgba(44, 63, 88, 0.3490196078);
    padding: 5px 8px;
    opacity: 1;
    border: 0;
    img {
      vertical-align: sub;
    }
    &:hover {
      img {
        opacity: 0.6;
      }
    }
  }
  .btn__close {
    &:first-child {
      &:active {
        background-color: var(--white-color);
      }
    }
  }
}
.merge__wrapper {
  .merging__data {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 367px));
    grid-auto-rows: auto;
    gap: 40px;
    grid-auto-flow: dense;
    &__item {
      background-color: var(--white-color);
      padding: 15px 8px;
      border: 1px solid var(--border-color);
      border-radius: var(--pdl-radius);
      margin-bottom: 22px;
      &__heading {
        padding: 0 5px;
        p {
          font-size: 20px;
          text-align: left;
          font-family: 'Outfit-Regular';
        }
        span {
          font-size: 10px;
          margin-bottom: 15px;
          text-transform: uppercase;
          display: inline-block;
          color: var(--text-primary-color);
        }
      }
      &:nth-child(n) {
        border-top-width: 6px;
      }
      &:nth-child(3n + 1) {
        border-top-color: #f3bec0;
      }
      &:nth-child(3n + 2) {
        border-top-color: #96ae9f;
      }
      &:nth-child(3n + 3) {
        border-top-color: var(--gray-text);
      }
      &__container {
        max-height: 600px;
        overflow-y: auto;
        padding: 0 5px;
      }
    }
    &__content {
      border-bottom: 1px solid #f0efef;
      padding: 8px 0;
      p {
        color: #6b788e;
        margin-bottom: 0;
        font-size: 14px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .action__form {
    width: auto;
    .btn__secondary,
    .btn__primary {
      width: auto;
      padding: 9px 20px;
    }
  }
}
.sub__text {
  color: var(--text-secondary-color);
}
.mendate__field {
  color: var(--err-color);
}
.help_text {
  color: #e26166;
  font-size: 10px;
  float: right;
  margin-top: 5px;
}
.note__section {
  background-color: #ea54551f;
  p,
  span {
    color: #ea5455;
    padding: 8px 10px;
    margin: 0;
  }
  p {
    font-family: 'Outfit-SemiBold';
    text-transform: uppercase;
  }
  span {
    display: block;
    background: linear-gradient(
      180deg,
      rgba(234, 84, 85, 0.16) 0%,
      rgba(255, 255, 255, 0) 94.05%
    );
    font-family: 'Outfit-light';
  }
}
.reorder__note {
  background-color: #f9ede2;
  p,
  span {
    color: #ff9f43;
    padding: 8px 10px;
    margin: 0;
  }
  p {
    font-family: 'Outfit-SemiBold';
    text-transform: uppercase;
  }
  span {
    display: block;
    background: linear-gradient(
      180deg,
      rgba(255, 159, 67, 0.16) 0%,
      rgba(255, 255, 255, 0) 94.05%
    );
    font-family: 'Outfit-light';
  }
}
.font-12 {
  font-size: 12px !important;
}

.form-check-input.radio__box[type='radio'] {
  border-radius: 0.25em;
  font-size: 16px;
  border-color: var(--text-secondary-color);
  border-width: 2px;
  margin-top: 0;

  &:checked {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    background-color: var(--pdl-green);
    border-color: var(--pdl-green);
  }
}
.required {
  position: relative;
  &:after {
    content: '*';
    color: var(--err-color);
    position: absolute;
    margin-left: 3px;
    top: 65%;
    transform: translateY(-50%);
  }
}
.label-color {
  color: var(--text-secondary-color);
}
.font-12,
.font-size-12 {
  font-size: 12px;
}
.line-height-12 {
  line-height: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
$randomNumber: random(3);

.rounded-image-init {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.color_combo1 {
  background-color: #e2fff0;
  color: #1db575;
}
.color_combo2 {
  background-color: var(--light-blue);
  color: var(--link-blue);
}
.color_combo3 {
  background-color: #ffebe2;
  color: #b56a33;
}
.color_combo4 {
  background-color: #dfe2e6;
  color: #2b4a36;
}
.color_combo5 {
  background-color: #f0ede4;
  color: #858174;
}
.color_combo6 {
  background-color: #e6e6f9;
  color: #858174;
}
.f-12 {
  font-size: 12px !important;
}
.toast.toast__warning__bg {
  background: var(--warning-alert);
}

.mob-only {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.desktop-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.large-desktop-only {
  @media screen and (max-width: 1399px) {
    display: none;
  }
}
.mobile-desktop-only {
  @media screen and (min-width: 1400px) {
    display: none;
  }
}
.greenBorderText {
  border-left: 3px solid #477958;
  padding-left: 12px;
}

.seperatorLine {
  border: 0.5px solid #f5f6f7;
}
.eq-grid {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.col-gap-12 {
  column-gap: 12px;
}

table.table {
  &.data-loading {
    position: relative;
    min-height: 200px;
  }
}
.pac-container {
  z-index: 9999;
}
.form-check-input:disabled {
  opacity: 1 !important;
}
.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.f-31 {
  font-size: 31px !important;
}

 
  .ngb-dp-weekday {
  color: var(--pdl-green) !important;
  font-style: normal  !important;
}
.ngb-dp-arrow-btn {
  color: var(--pdl-green) !important;

}

.ngb-dp-week {
  .bg-primary {
    background-color: var(--pdl-green) !important;
  }
}

