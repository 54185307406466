@font-face {
    font-family: "Outfit-Regular";
    src: url("Outfit-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-Medium";
    src: url("Outfit-Medium.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-Light";
    src: url("Outfit-Light.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-ExtraLight";
    src: url("Outfit-ExtraLight.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-Thin";
    src: url("Outfit-Thin.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-SemiBold";
    src: url("Outfit-SemiBold.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-Bold";
    src: url("Outfit-Bold.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-ExtraBold";
    src: url("Outfit-ExtraBold.ttf") format('truetype');
}
@font-face {
    font-family: "Outfit-Black";
    src: url("Outfit-Black.ttf") format('truetype');
}
@font-face {
    font-family: "Unna-Bold";
    src: url("Unna-Bold.ttf") format('truetype');
}
@font-face {
    font-family: "Unna-BoldItalic";
    src: url("Unna-BoldItalic.ttf") format('truetype');
}
@font-face {
    font-family: "Unna-Regular";
    src: url("Unna-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Unna-Italic";
    src: url("Unna-Italic.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-Bold";
    src: url("MuktaMahee-Bold.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-ExtraBold";
    src: url("MuktaMahee-ExtraBold.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-ExtraLight";
    src: url("MuktaMahee-ExtraLight.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-Light";
    src: url("MuktaMahee-Light.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-Medium";
    src: url("MuktaMahee-Medium.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-Regular";
    src: url("MuktaMahee-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "MuktaMahee-SemiBold";
    src: url("MuktaMahee-SemiBold.ttf") format('truetype');
}