@mixin btn__primary {
    background-color: #477958;
    color: #ffffff;
    font-size: 16px;
    border: 0;
    max-width: 100%;
    border: 1px solid #477958;
    font-family: "Outfit-Regular";
    &:focus {
        background-color: #477958;
        color: #ffffff;
        border: 1px solid #477958;
    }
    &:hover {
        opacity: 0.8;
        transition: ease 1s;
        border: 1px solid #477958;
    }
}
@mixin form__field {
    border: 1px solid #EAE6D9;
    border-radius: 4px;
    background-color: #ffffff;
    font-family: "Outfit-Light";
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0em;
    padding: 11px 10px;

}
@mixin btn__secondary {
    color: #477958;
    font-family: "Outfit-Regular";
    font-size: 14px;
    border: 1px solid #477958;
    &:focus {
        color: #477958;
        border: 1px solid #477958;
    }
}
@mixin err__msg {
    color: #dc3545;
    position: absolute;
    bottom: 0;
    font-size: 10px;
}